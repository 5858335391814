import { navigate } from '@reach/router';
import React, { FC, useEffect } from 'react';
import { track } from 'utils/analytics';

interface AtAGlance {}

const AtAGlance: FC<AtAGlance> = () => {
  useEffect(() => {
    track('At A Glance Brochure Viewed');
    navigate('/pdfs/at-a-glance.pdf');
  }, []);

  return <></>;
};

export default AtAGlance;
